import * as React from 'react';
import { ProductType } from '../../constants';

const styles = require('./staffVisual.module.scss');
const wakeParkVisual = require('../assets/staff-visual-2x.png');
const aquaParkVisual = require('../assets/aqua-park-staff-visual.png');
const surfParkVisual = require('../assets/surf-park-staff-visual.png');

interface Props {
  type: ProductType;
}

export const StaffVisual: React.FunctionComponent<Props> = ({ type }) => {
  const renderVisual = () => {
    switch (type) {
      case ProductType.AQUAPARK: {
        return <img className={styles.staffVisualImage} src={aquaParkVisual} alt="Check In interface for the staff" />;
      }
      case ProductType.SURFPARK: {
        return <img className={styles.staffVisualImage} src={surfParkVisual} alt="Booking interface for the staff" />;
      }
      default: {
        return <img className={styles.staffVisualImage} src={wakeParkVisual} alt="User interface for the staff" />;
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.staffVisualImageWrapper}>{renderVisual()}</div>
      {type === ProductType.WAKEPARK && (
        <div className={styles.staffVisualIllustration}>
          <svg viewBox="0 0 269 374">
            <g fill="none" fill-rule="evenodd">
              <text
                fill="#4A90E2"
                font-family="Roboto-Light, Roboto"
                font-size="16"
                font-weight="300"
                letter-spacing=".763"
                transform="translate(1)"
              >
                <tspan x="87" y="212">
                  RFID wristband
                </tspan>
              </text>
              <path stroke="#4A90E2" d="M83 209H73v25M83 349H73v-54" />
              <path
                fill="#FFD046"
                d="M248 0c-11.597 0-21 11.382-21 15.5S236.403 31 248 31s21-11.705 21-15.5S259.597 0 248 0zm0 25.833c-8.046 0-14.726-7.76-15.682-10.333.646-1.739 3.909-5.836 8.434-8.318a7.58 7.58 0 0 0-.693 3.151c0 4.28 3.526 7.75 7.875 7.75 4.35 0 7.875-3.47 7.875-7.75 0-1.15-.27-2.232-.727-3.21 4.489 2.438 7.778 6.517 8.563 8.377-1.158 2.749-7.76 10.333-15.645 10.333z"
              />
              <path fill="#7D818F" d="M119.469 238L9.999 299.75l15.532 8.938L135 246.938z" />
              <path fill="#3E4449" d="M88.344 273.27l-16.562 9.126-15.532-8.938 16.563-9.125z" />
              <path fill="#231F20" d="M88.344 267.188l-16.562 9.125-15.532-8.938 16.563-9.125z" />
              <g transform="translate(104.498 326.096)">
                <path
                  fill="#4A90E2"
                  d="M5.482.099c.302.08.605.155.906.238 2.319.637 3.922 2.893 3.62 5.09-.35 2.54-2.314 4.332-4.826 4.407C2.711 9.907.572 8.217.13 5.84-.371 3.154 1.444.672 4.239.217c.12-.02.235-.078.353-.118h.89z"
                  mask="url(#b)"
                />
              </g>
              <g transform="translate(94 328.929)">
                <path
                  fill="#4A90E2"
                  d="M19.123 31.006c1.362-.374 2.635-.716 3.898-1.085.11-.032.223-.24.233-.373.075-1.066.13-2.133.18-3.2.005-.11-.032-.266-.111-.327-.766-.593-1.544-1.17-2.434-1.837 0 1.176.03 2.181-.014 3.183-.017.39-.137.804-.32 1.154-.426.82-.92 1.606-1.432 2.485M30.937 7.014l1.301 6.94 4.062-3.56-5.363-3.38M19.038 12.33c1.25 2.21 2.457 4.281 3.597 6.386.455.842 1.033 1.517 1.834 2.062.97.659 1.876 1.408 2.836 2.083.873.613 1.205 1.415 1.163 2.447-.05 1.206-.011 2.415-.011 3.682.836-.054 1.776-.13 2.717-.173 3.248-.143 6.475-.002 9.638.796 1.648.417 1.724.632.638 1.886-2.12 2.448-4.716 4.36-7.467 6.08-5.294 3.306-11.098 5.257-17.315 6.116-3.196.441-6.396.641-9.619.356-.42-.038-.848-.066-1.256-.165-.61-.148-1.454-.18-1.749-.576-.483-.649-.634-1.544-.857-2.353-.048-.174.145-.477.314-.63 1.17-1.065 2.3-2.189 3.57-3.132 1.306-.969 2.762-1.745 4.14-2.626.206-.133.376-.353.5-.567.6-1.037 1.17-2.091 1.767-3.13.69-1.202 1.418-2.384 2.084-3.598.188-.343.216-.769.314-1.157.01-.039.001-.082 0-.123-.011-1.295.219-2.65-.103-3.868-.315-1.194-1.19-2.25-1.82-3.366-.714-1.26-1.471-2.498-2.13-3.784-.557-1.088-1.328-1.973-2.222-2.809C6.64 9.4 3.718 6.595.783 3.803-.191 2.876-.262 1.689.6.858 1.47.017 2.663.065 3.642.993 6.1 3.317 8.56 5.64 11.002 7.979c.31.296.618.418 1.056.417a3155.19 3155.19 0 0 1 15.184-.005c.602.001 1.203.074 1.87.117-.19-1.058-.385-2.164-.59-3.269-.132-.717.132-1.196.753-1.333.61-.136 1.035.207 1.267.904.098.297.309.626.568.795a220.417 220.417 0 0 0 5.805 3.654c.403.244.93.402 1.404.411 2.18.041 4.362.016 6.543.016.148 0 .34-.042.434.03.209.16.507.378.51.577.005.207-.261.46-.47.616-.125.092-.368.041-.558.042-2.224.002-4.447-.01-6.67.014-.286.003-.633.113-.843.293a225.074 225.074 0 0 0-4.385 3.844c-.174.156-.3.424-.333.655-.08.556-.417.952-.94.998-.523.046-1.002-.323-1.113-.894-.228-1.17-.438-2.342-.656-3.513-.042-.221-.09-.442-.165-.807-.722.939-1.7.791-2.636.793-2.288.001-4.575-.002-6.862-.004h-1.137"
                />
              </g>
              <g opacity=".6">
                <g transform="translate(155.498 326.096)">
                  <path
                    fill="#4A90E2"
                    d="M5.482.099c.302.08.605.155.906.238 2.319.637 3.922 2.893 3.62 5.09-.35 2.54-2.314 4.332-4.826 4.407C2.711 9.907.572 8.217.13 5.84-.371 3.154 1.444.672 4.239.217c.12-.02.235-.078.353-.118h.89z"
                  />
                </g>
                <g transform="translate(145 328.929)">
                  <path
                    fill="#4A90E2"
                    d="M19.123 31.006c1.362-.374 2.635-.716 3.898-1.085.11-.032.223-.24.233-.373.075-1.066.13-2.133.18-3.2.005-.11-.032-.266-.111-.327-.766-.593-1.544-1.17-2.434-1.837 0 1.176.03 2.181-.014 3.183-.017.39-.137.804-.32 1.154-.426.82-.92 1.606-1.432 2.485M30.937 7.014l1.301 6.94 4.062-3.56-5.363-3.38M19.038 12.33c1.25 2.21 2.457 4.281 3.597 6.386.455.842 1.033 1.517 1.834 2.062.97.659 1.876 1.408 2.836 2.083.873.613 1.205 1.415 1.163 2.447-.05 1.206-.011 2.415-.011 3.682.836-.054 1.776-.13 2.717-.173 3.248-.143 6.475-.002 9.638.796 1.648.417 1.724.632.638 1.886-2.12 2.448-4.716 4.36-7.467 6.08-5.294 3.306-11.098 5.257-17.315 6.116-3.196.441-6.396.641-9.619.356-.42-.038-.848-.066-1.256-.165-.61-.148-1.454-.18-1.749-.576-.483-.649-.634-1.544-.857-2.353-.048-.174.145-.477.314-.63 1.17-1.065 2.3-2.189 3.57-3.132 1.306-.969 2.762-1.745 4.14-2.626.206-.133.376-.353.5-.567.6-1.037 1.17-2.091 1.767-3.13.69-1.202 1.418-2.384 2.084-3.598.188-.343.216-.769.314-1.157.01-.039.001-.082 0-.123-.011-1.295.219-2.65-.103-3.868-.315-1.194-1.19-2.25-1.82-3.366-.714-1.26-1.471-2.498-2.13-3.784-.557-1.088-1.328-1.973-2.222-2.809C6.64 9.4 3.718 6.595.783 3.803-.191 2.876-.262 1.689.6.858 1.47.017 2.663.065 3.642.993 6.1 3.317 8.56 5.64 11.002 7.979c.31.296.618.418 1.056.417a3155.19 3155.19 0 0 1 15.184-.005c.602.001 1.203.074 1.87.117-.19-1.058-.385-2.164-.59-3.269-.132-.717.132-1.196.753-1.333.61-.136 1.035.207 1.267.904.098.297.309.626.568.795a220.417 220.417 0 0 0 5.805 3.654c.403.244.93.402 1.404.411 2.18.041 4.362.016 6.543.016.148 0 .34-.042.434.03.209.16.507.378.51.577.005.207-.261.46-.47.616-.125.092-.368.041-.558.042-2.224.002-4.447-.01-6.67.014-.286.003-.633.113-.843.293a225.074 225.074 0 0 0-4.385 3.844c-.174.156-.3.424-.333.655-.08.556-.417.952-.94.998-.523.046-1.002-.323-1.113-.894-.228-1.17-.438-2.342-.656-3.513-.042-.221-.09-.442-.165-.807-.722.939-1.7.791-2.636.793-2.288.001-4.575-.002-6.862-.004h-1.137"
                  />
                </g>
              </g>
              <g opacity=".2">
                <g transform="translate(205.498 326.096)">
                  <path
                    fill="#4A90E2"
                    d="M5.482.099c.302.08.605.155.906.238 2.319.637 3.922 2.893 3.62 5.09-.35 2.54-2.314 4.332-4.826 4.407C2.711 9.907.572 8.217.13 5.84-.371 3.154 1.444.672 4.239.217c.12-.02.235-.078.353-.118h.89z"
                    mask="url(#j)"
                  />
                </g>
                <g transform="translate(195 328.929)">
                  <path
                    fill="#4A90E2"
                    d="M19.123 31.006c1.362-.374 2.635-.716 3.898-1.085.11-.032.223-.24.233-.373.075-1.066.13-2.133.18-3.2.005-.11-.032-.266-.111-.327-.766-.593-1.544-1.17-2.434-1.837 0 1.176.03 2.181-.014 3.183-.017.39-.137.804-.32 1.154-.426.82-.92 1.606-1.432 2.485M30.937 7.014l1.301 6.94 4.062-3.56-5.363-3.38M19.038 12.33c1.25 2.21 2.457 4.281 3.597 6.386.455.842 1.033 1.517 1.834 2.062.97.659 1.876 1.408 2.836 2.083.873.613 1.205 1.415 1.163 2.447-.05 1.206-.011 2.415-.011 3.682.836-.054 1.776-.13 2.717-.173 3.248-.143 6.475-.002 9.638.796 1.648.417 1.724.632.638 1.886-2.12 2.448-4.716 4.36-7.467 6.08-5.294 3.306-11.098 5.257-17.315 6.116-3.196.441-6.396.641-9.619.356-.42-.038-.848-.066-1.256-.165-.61-.148-1.454-.18-1.749-.576-.483-.649-.634-1.544-.857-2.353-.048-.174.145-.477.314-.63 1.17-1.065 2.3-2.189 3.57-3.132 1.306-.969 2.762-1.745 4.14-2.626.206-.133.376-.353.5-.567.6-1.037 1.17-2.091 1.767-3.13.69-1.202 1.418-2.384 2.084-3.598.188-.343.216-.769.314-1.157.01-.039.001-.082 0-.123-.011-1.295.219-2.65-.103-3.868-.315-1.194-1.19-2.25-1.82-3.366-.714-1.26-1.471-2.498-2.13-3.784-.557-1.088-1.328-1.973-2.222-2.809C6.64 9.4 3.718 6.595.783 3.803-.191 2.876-.262 1.689.6.858 1.47.017 2.663.065 3.642.993 6.1 3.317 8.56 5.64 11.002 7.979c.31.296.618.418 1.056.417a3155.19 3155.19 0 0 1 15.184-.005c.602.001 1.203.074 1.87.117-.19-1.058-.385-2.164-.59-3.269-.132-.717.132-1.196.753-1.333.61-.136 1.035.207 1.267.904.098.297.309.626.568.795a220.417 220.417 0 0 0 5.805 3.654c.403.244.93.402 1.404.411 2.18.041 4.362.016 6.543.016.148 0 .34-.042.434.03.209.16.507.378.51.577.005.207-.261.46-.47.616-.125.092-.368.041-.558.042-2.224.002-4.447-.01-6.67.014-.286.003-.633.113-.843.293a225.074 225.074 0 0 0-4.385 3.844c-.174.156-.3.424-.333.655-.08.556-.417.952-.94.998-.523.046-1.002-.323-1.113-.894-.228-1.17-.438-2.342-.656-3.513-.042-.221-.09-.442-.165-.807-.722.939-1.7.791-2.636.793-2.288.001-4.575-.002-6.862-.004h-1.137"
                  />
                </g>
              </g>
              <path
                className={styles.path}
                stroke="#FFD046"
                stroke-dasharray="1,6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M50.346 253L1 225.586 250 75.991 248.906 41"
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};
